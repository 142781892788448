import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Space,
  Button,
  InputNumber,
  Input,
  Select,
  DatePicker,
  Form,
  Typography,
  Statistic,
  Descriptions,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { convertToSexagesimal, formatHHMMValue } from "pages/timesheet/timeConverters";
import { employeeAPI } from "@api";

const { Text } = Typography;

const MobileTimesheetLine = ({
  line: {
    date,
    unit_amount,
    project_id,
    description,
    task_id,
    field: { name, key, ...restField },
    remove,
    handleNewLineRef,
    ...restLine
  },
  isEditing,
  language,
  t,
  timesheet,
}) => {
  const [tasks, setTasks] = useState([]);
  const formInstance = Form.useFormInstance();
  const currentDate = Form.useWatch(["lines", name, 'date'], formInstance)

  const { data: options, isLoading } = useQuery({
    queryKey: ["projectOptions", currentDate?.format("YYYY-MM-DD")],
    queryFn: async ({ queryKey }) => {
      const { data } = await employeeAPI.getMyProjectsAndTasks(queryKey[1])
      return {
        projects: data.projects.map((project) => ({
          label: project.name,
          value: project.id,
        })),
        tasksByProjectId: data.tasksByProjectId,
      };
    },
    refetchOnWindowFocus: false,
    enabled: !!currentDate,
    staleTime: 180000,
  })

  useEffect(() => {
    buildTaskOptions();
  }, [project_id, options]);

  const buildTaskOptions = newSelectedProject => {
    const newProject = newSelectedProject || project_id;
    const newTasks = [];
    if (options?.tasksByProjectId[newProject]) {
      for (const { id, name } of options.tasksByProjectId[newProject]) {
        newTasks.push({ value: id, label: name })
      }
    }
    setTasks(newTasks);
    if (newSelectedProject) formInstance.setFieldValue(["lines", name, "task_id"], null);
  }

  return (
    <>
      <Descriptions
        key={timesheet.id}
        bordered
        size="small"
        layout="vertical"
        column={2}
        style={{ width: "100%", textAlign: "center" }}
      >
        <Descriptions.Item
          label={t("timesheet.list.date")}
          span={isEditing ? 2 : 1}
        >
          <Form.Item
            {...restField}
            name={[name, "date"]}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {isEditing ? (
              <DatePicker
                allowClear={false}
                format={[
                  "L",
                  "DDMMYYYY",
                  "DD/MM/YYYY",
                  "MMDDYYYY",
                  "MM/DD/YYYY",
                ]}
                style={{ width: "100%" }}
                disabledDate={(current) =>
                  current < timesheet?.date_start.startOf("day") ||
                  current > timesheet?.date_end.endOf("day")
                }
                onChange={() => {
                  formInstance.setFieldsValue({
                    lines: {
                      [name]: {
                        project_id: null,
                        task_id: null,
                      }
                    }
                  });
                }}
              />
            ) : (
              <Text>{date.locale(language).format("L")}</Text>
            )}
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          label={t("timesheet.list.unit_amount")}
          span={isEditing ? 2 : 1}
        >
          <Form.Item
            {...restField}
            name={[name, "unit_amount"]}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {isEditing ? (
              <Input
                size="small"
                controls={false}
                style={{ width: "100%" }}
                maxLength={5}
                onBlur={({ target: { value } }) => {
                  let time
                  switch (true) {
                  case value.includes(":"):
                    time = formatHHMMValue(value)
                    break;
                  case value.includes(","):
                    time = convertToSexagesimal(value.replace(",", "."))
                    break;
                  default:
                    time = convertToSexagesimal(value)
                    break;
                  }
                  formInstance.setFieldValue(["lines", name, "unit_amount"], time)
                  handleNewLineRef()
                }}
              />
            ) : (
              <Statistic
                value={unit_amount}
                precision={2}
                decimalSeparator=":"
                valueStyle={{
                  fontSize: 14,
                }}
              />
            )}
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t("timesheet.list.project")} span={2}>
          <Form.Item
            {...restField}
            name={[name, "project_id"]}
            rules={[{ required: true, message: "" }]}
          >
            {isEditing ? (
              <Select
                disabled={isLoading}
                style={{ width: "100%" }}
                options={[...options?.projects || []]}
                dropdownAlign={"center"}
                onSelect={buildTaskOptions}
              />
            ) : (
              <Text>{restLine.project?.name}</Text>
            )}
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t("timesheet.list.task")} span={2}>
          <Form.Item
            {...restField}
            name={[name, "task_id"]}
            rules={[{ required: true, message: "" }]}
          >
            {isEditing ? (
              <Select
                disabled={isLoading}
                style={{ width: "100%" }}
                options={tasks}
                dropdownAlign={"center"}
              />
            ) : (
              <Text>{restLine.task?.name}</Text>
            )}
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t("timesheet.list.description")} span={2}>
          <Form.Item
            {...restField}
            name={[name, "description"]}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {isEditing ? (
              <Input style={{ width: "100%" }} />
            ) : (
              <Text>{description}</Text>
            )}
          </Form.Item>
        </Descriptions.Item>
        {isEditing && (
          <Descriptions.Item span={2}>
            <Space
              align="center"
              style={{
                width: "100%",
                justifyContent: "flex-end",
                padding: "6px 0",
              }}
            >
              <Button onClick={() => remove(name)} danger>
                <DeleteOutlined />
              </Button>
            </Space>
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default MobileTimesheetLine;
