import React, { StrictMode, Suspense } from "react";
import { Router } from "@router/Router";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "@configs/keycloak";
import { InitializingApp } from "@components";
import { updateStorageItem } from "@helpers/local-storage";
import { ConfigProvider } from "antd";
import { theme } from "@layout/ThemeSettings";
import { useTranslation } from "react-i18next";
import locale_en_US from "antd/locale/en_US";
import locale_es_ES from "antd/locale/es_ES";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "./App.css";

const locale = { en: locale_en_US, es: locale_es_ES };

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);

const queryClient = new QueryClient();

const App = () => {
  const { i18n } = useTranslation("common");
  dayjs.locale(i18n.language);

  const onKeycloakEvent = (event) => {
    if (!keycloak.authenticated) keycloak.login();
    if (
      event === "onAuthError" ||
      event === "onAuthRefreshError" ||
      event === "onAuthLogout"
    )
      keycloak.login();
  };

  const onKeycloakTokens = (tokens) => {
    if (tokens?.token) updateStorageItem("KC_TOKEN", JSON.stringify(tokens));
    else keycloak.login();
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onload: "login-required" }}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
      LoadingComponent={<InitializingApp />}
    >
      <StrictMode>
        <ConfigProvider theme={theme} locale={locale[i18n.language]}>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<InitializingApp />}>
              <Router />
              <ReactQueryDevtools initialIsOpen={false} />
            </Suspense>
          </QueryClientProvider>
        </ConfigProvider>
      </StrictMode>
    </ReactKeycloakProvider>
  );
};

export default App;
