import React from "react";
import { MainHeader } from "./MainHeader";
import { Layout } from "antd";

const { Content, Footer } = Layout;


export const AppLayout = ({ children }) => (
  <Layout>
    <MainHeader />
    <Content
      style={{
        marginTop: "67px",
        padding: "16px",
        minHeight: "calc(100vh - 135px)",
      }}
    >
      {children}
    </Content>
    <Footer style={{ textAlign: "center" }}>ExoMindset ©2023</Footer>
  </Layout>
);
