export const theme = {
  components: {
    Typography: {
      colorTextHeading: "#48545b",
      fontSizeHeading3: 22,
      lineHeightHeading3: 0,
      fontWeightStrong: 500,
    },
  },
  token: {
    colorPrimary: "#2966C6",
  },
};
