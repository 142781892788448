import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { AppLayout } from "../layout/Layout";


const OverviewPage = lazy(() => import('@pages/overview/OverviewPage'));
const NotFoundPage = lazy(() => import('@pages/notFound/NotFoundPage'));
const AssignmentsPage = lazy(() => import('@pages/assignments/AssignmentsPage'));
const ContractsPage = lazy(() => import('@pages/contracts/ContractsPage'));
const FeedbackPage = lazy(() => import('@pages/feedback/FeedbackPage'));
const PayrollsPage = lazy(() => import('@pages/payroll/PayrollsPage'));
const TimesheetPage = lazy(() => import('@pages/timesheet/TimesheetPage'));
const TimesheetScreen = lazy(() => import('@pages/timesheet/TimesheetScreen'))


const routesArray = [
  {
    path: "/",
    element: <OverviewPage />,
  },
  {
    path: "/assignments",
    element: <AssignmentsPage />,
  },
  {
    path: "/contracts",
    element: <ContractsPage />,
  },
  {
    path: "/timesheets/*",
    element: <TimesheetPage />,
  },
  {
    path: "/timesheets/:id/*",
    element: <TimesheetScreen />
  },
  {
    path: "/feedback",
    element: <FeedbackPage />,
  },
  {
    path: "/payrolls",
    element: <PayrollsPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
};

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
