import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Typography,
  Statistic,
  Row,
  Col,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatHHMMValue, convertToSexagesimal } from "pages/timesheet/timeConverters";
import { useQuery } from "@tanstack/react-query";
import { employeeAPI } from "@api";

const { Text } = Typography;

const TimesheetLine = ({
  line: {
    date,
    unit_amount,
    project_id,
    description,
    task_id,
    field: { name, key, ...restField },
    remove,
    handleNewLineRef,
    ...restLine
  },
  isEditing,
  language,
  timesheet,
}) => {
  const [tasks, setTasks] = useState([]);
  const formInstance = Form.useFormInstance();
  const currentDate = Form.useWatch(["lines", name, 'date'], formInstance)

  const { data: options, isLoading } = useQuery({
    queryKey: ["projectOptions", currentDate?.format("YYYY-MM-DD")],
    queryFn: async ({ queryKey }) => {
      const { data } = await employeeAPI.getMyProjectsAndTasks(queryKey[1])
      return {
        projects: data.projects.map((project) => ({
          label: project.name,
          value: project.id,
        })),
        tasksByProjectId: data.tasksByProjectId,
      };
    },
    refetchOnWindowFocus: false,
    enabled: !!currentDate,
    staleTime: 180000,
  })

  useEffect(() => {
    buildTaskOptions();
  }, [project_id, options]);

  const buildTaskOptions = newSelectedProject => {
    const newProject = newSelectedProject || project_id;
    const newTasks = [];
    if (options?.tasksByProjectId[newProject]) {
      for (const { id, name } of options.tasksByProjectId[newProject]) {
        newTasks.push({ value: id, label: name })
      }
    }
    setTasks(newTasks);
    if (newSelectedProject) formInstance.setFieldValue(["lines", name, "task_id"], null);
  }

  if (isEditing)
    return (
      <Row wrap={false} key={key} style={{ width: "100%", padding: 0, alignItems: 'center' }}>
        <Col
          flex="0 0 101px"
          style={{
            position: "sticky",
            left: 0,
            backgroundColor: "#fff",
            zIndex: 1,
            padding: '0px 4px 0px 8px'
          }}
        >
          <Form.Item
            {...restField}
            style={{ marginBottom: 0 }}
            name={[name, "date"]}
            rules={[{ required: true, message: "" }]}
          >
            <DatePicker
              size="small"
              autoFocus
              allowClear={false}
              format={[
                "L",
                "DDMMYYYY",
                "DD/MM/YYYY",
                "MMDDYYYY",
                "MM/DD/YYYY",
              ]}
              style={{ width: "100%" }}
              disabledDate={current =>
                current < timesheet?.date_start.startOf("day") ||
                  current > timesheet?.date_end.endOf("day")
              }
              suffixIcon={null}
              onChange={() => {
                formInstance.setFieldsValue({
                  lines: {
                    [name]: {
                      project_id: null,
                      task_id: null,
                    }
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col flex="1 0 200px" style={{ maxWidth: "300px", padding: '0px 4px' }}>
          <Form.Item
            {...restField}
            style={{ marginBottom: 0 }}
            name={[name, "project_id"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              disabled={isLoading}
              size="small"
              style={{ width: "100%", textAlign: 'left' }}
              options={[...options?.projects || []]}
              dropdownAlign={"center"}
              onSelect={buildTaskOptions}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col flex="1 0 200px" style={{ maxWidth: "300px", padding: '0px 4px' }}>
          <Form.Item
            {...restField}
            style={{ marginBottom: 0 }}
            name={[name, "task_id"]}
            rules={[{ required: true, message: "" }]}
          >
            <Select
              disabled={isLoading}
              size="small"
              style={{ width: "100%", textAlign: 'left' }}
              options={tasks}
              dropdownAlign={"center"}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col flex="1 0 280px" style={{ padding: '0px 4px' }}>
          <Form.Item
            {...restField}
            style={{ marginBottom: 0 }}
            name={[name, "description"]}
            rules={[{ required: true, message: "" }]}
          >
            <Input size="small" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col
          flex="0 0 60px"
          style={{
            position: "sticky",
            zIndex: 1,
            right: '40px',
            textAlign: "center",
            backgroundColor: "#fff",
            padding: '0px 4px'
          }}
        >
          <Form.Item
            {...restField}
            name={[name, "unit_amount"]}
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "" }]}
          >
            <Input
              size="small"
              controls={false}
              style={{ width: "100%" }}
              maxLength={5}
              onBlur={({ target: { value } }) => {
                let time
                switch (true) {
                case value.includes(":"):
                  time = formatHHMMValue(value)
                  break;
                case value.includes(","):
                  time = convertToSexagesimal(value.replace(",", "."))
                  break;
                default:
                  time = convertToSexagesimal(value)
                  break;
                }
                formInstance.setFieldValue(["lines", name, "unit_amount"], time)
                handleNewLineRef()
              }}
            />
          </Form.Item>
        </Col>
        <Col
          flex="0 0 40px"
          style={{
            display: 'flex',
            alignItems: 'center',
            position: "sticky",
            right: 0,
            zIndex: 1,
            backgroundColor: "#fff",
            padding: '0px 4px'
          }}
        >
          <Button size="small" type="link" onClick={() => remove(name)} danger>
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
    );

  return (
    <Row wrap={false} key={key} style={{ width: "100%", padding: 0, alignItems: 'center', borderBottom: '1px solid #EFEFEF' }}>
      <Col
        flex="0 0 101px"
        style={{
          textAlign: "left",
          position: "sticky",
          left: 0,
          backgroundColor: "#fff",
          zIndex: 1,
          padding: '4px 4px 4px 8px'
        }}
      >
        <Text>{date?.locale(language)?.format("L")}</Text>
      </Col>
      <Col flex="1 0 200px" style={{ maxWidth: "300px", textAlign: "left", padding: '4px' }}>
        <Text>
          {restLine.project?.name}
        </Text>
      </Col>
      <Col flex="1 0 200px" style={{ maxWidth: "300px", textAlign: "left", padding: '4px' }}>
        <Text>{restLine.task?.name}</Text>
      </Col>
      <Col flex="1 0 280px" style={{ textAlign: "left", padding: '4px', }}>
        <Text>{description}</Text>
      </Col>
      <Col
        flex="0 0 60px"
        style={{
          backgroundColor: "#fff",
          padding: '4px',
          textAlign: 'left',
          position: "sticky",
          zIndex: 1,
          right: 0
        }}
      >
        <Statistic
          value={unit_amount}
          precision={2}
          decimalSeparator=":"
          valueStyle={{
            fontSize: 14,
          }}
        />
      </Col>
    </Row>
  );
};

export default TimesheetLine;
