import React, { useState } from "react";
import { Modal, Form, notification, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { employeeAPI } from "@api";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";


const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "This Month",
    value: [
      dayjs().startOf("month"),
      dayjs().endOf("month"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];

const CreateTimesheetModal = ({ open, onCancel }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const dates = Form.useWatch('dates', form);
  const { t } = useTranslation("common");
  const { t: tError } = useTranslation("error");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (values) => {
      setConfirmLoading(true);
      return employeeAPI.createTimesheet(values);
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["timesheets"], prev => {
        return {
          myTimesheets: [...prev.myTimesheets, data ],
          activeTimesheets: [...prev.activeTimesheets, data ]
        }
      });
      form.resetFields();
      onCancel();
    },
    onError: ({ response }) => {
      notification.error({
        message: tError(response.data.message),
      });
      setConfirmLoading(false);
    },
    onSettled: ({ data }) => {
      setConfirmLoading(false);
      navigate(`/timesheets/${data.id}`);
    },
  });

  return (
    <Modal
      title={t("timesheets.form.title")}
      open={open}
      onOk={() => form.submit()}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      okText={t("timesheets.form.btnSubmit")}
      okButtonProps={{ disabled: !dates }}
      confirmLoading={confirmLoading}
      width={400}
      bodyStyle={{ paddingTop: '18px' }}
    >
      <Form
        form={form}
        name="createTimesheetForm"
        onFinish={({ dates }) =>
          mutate({
            dateStart: dates[0].format("YYYY-MM-DD"),
            dateEnd: dates[1].format("YYYY-MM-DD"),
          })
        }
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label={t("timesheets.form.dates")}
          name="dates"
          rules={[
            { required: true, message: t("timesheets.form.validation.dates") },
          ]}
        >
          <RangePicker
            presets={rangePresets}
            format={["L", "DDMMYYYY", "DD/MM/YYYY", "MMDDYYYY", "MM/DD/YYYY"]}
            style={{ width: "100%", textAlign: "center" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTimesheetModal;
