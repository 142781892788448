import { Link } from "react-router-dom";


export const BaseNavbar = [
  {
    key: "overview",
    label: (
      <Link className="nav-link" to={"/"}>
        overview
      </Link>
    ),
  },
  {
    key: "assignments",
    label: (
      <Link className="nav-link" to={"/assignments"}>
        assignments
      </Link>
    ),
  },
  {
    key: "contracts",
    label: (
      <Link className="nav-link" to={"/contracts"}>
        contracts
      </Link>
    ),
  },
  {
    key: "timesheets",
    label: (
      <Link className="nav-link" to={"/timesheets"}>
        timesheets
      </Link>
    ),
  },
  {
    key: "feedback",
    label: (
      <Link className="nav-link" to={"/feedback"}>
        feedback
      </Link>
    ),
  },
  {
    key: "payrolls",
    label: (
      <Link className="nav-link" to={"/payrolls"}>
        payrolls
      </Link>
    ),
  },
  {
    key: "training",
    label: (
      <Link className="nav-link" to={"https://sites.google.com/exomindset.co/trainingcenter/home"} target="_blank">
        training
      </Link>
    ),
  },
];
