import instance from "./api";

export default {
  getMyInfo: async () => {
    return instance.get("/employee-portal/me");
  },
  updateMyInfo: async (data) => {
    return instance.put(`/employee-portal/me`, data);
  },
  updateProfilePicture: function (picture) {
    return instance.put(
      `/employee-portal/me/profile-picture`,
      picture,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    )
  },
  getMyFeedback: async () => {
    return instance.get("/employee-portal/feedbacks");
  },
  getMyPayrolls: async () => {
    return instance.get("/employee-portal/payrolls");
  },
  getMyContracts: async () => {
    return instance.get("/employee-portal/contracts");
  },
  getMyLanguages: async (params) => {
    return instance.get("/employee-portal/language-assessments", { params });
  },
  getMyAssignments: async () => {
    return instance.get("/employee-portal/assignments");
  },
  getMyProjectsAndTasks: async (date) => {
    return instance.get("/employee-portal/projects-and-tasks", { params: { date } });
  },
  getMyTimesheets: async () => {
    return instance.get("/employee-portal/timesheets");
  },
  getTimesheet: async (timesheetId) => {
    return instance.get(`/employee-portal/timesheets/${timesheetId}`);
  },
  createTimesheet: async (data) => {
    return instance.post("/employee-portal/timesheets", data);
  },
  updateTimesheet: async (timesheetId, data) => {
    return instance.put(`/employee-portal/timesheets/${timesheetId}`, data);
  },
  deleteTimesheet: async (timesheetId) => {
    return instance.delete(`/employee-portal/timesheets/${timesheetId}`);
  },
  submitTimesheetToReviewer: async (timesheetId) => {
    return instance.post(
      `/employee-portal/timesheets/${timesheetId}/submit-to-reviewer`
    );
  },
  getTimesheetLines: async (timesheedId) => {
    return instance.get(`/employee-portal/timesheet/${timesheedId}/lines`);
  },
  updateTimesheetLines: async (timesheetId, data) => {
    return instance.put(
      `/employee-portal/timesheets/${timesheetId}/lines`,
      data
    );
  },
  getAllContacts: function () {
    return instance.get("employee-portal/contacts");
  },
  getContactRelations: function () {
    return instance.get(`employee-portal/contacts-relations`);
  },
  createContact: function (contact) {
    return instance.post("employee-portal/contacts", contact);
  },
  updateContact: function (contactId, contact) {
    return instance.put(`employee-portal/contacts/${contactId}`, contact);
  },
  deleteContact: function (contactId) {
    return instance.delete(`employee-portal/contacts/${contactId}`)
  },
  getTimesheetSummary: function (timesheetId) {
    return instance.get(`employee-portal/timesheets/${timesheetId}/summary`)
  }
};
