export const convertToSexagesimal = decimalValue => {
  const hours = Math.floor(decimalValue);
  const minutes = Math.round((decimalValue % 1) * 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const formatHHMMValue = value => {
  let [hours, minutes] = value.split(":").map(n => parseFloat(n));
  if (minutes === 60) {
    hours += 1
    minutes = 0
  }
  if (minutes > 60) {
    hours += Math.floor(minutes / 60)
    minutes = Math.round(60 * ((minutes / 60) % 1))
  }
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const convertToDecimal = sexagesimalValue => {
  if (!sexagesimalValue.includes(":")) return 0
  const [hours, minutes] = sexagesimalValue.split(':').map(Number);
  return hours + minutes / 60;
};